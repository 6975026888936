.imageWrapper{
    display:inline-block;
    border:1px solid black;
    border-radius: 5px;
    cursor: pointer;
    margin: auto;
    margin-top: 10px;
    margin-left: 10px;
    padding: 10px;
}

