.navbar-default {
    background-color: #F8F8F8;
    border-color: #E7E7E7;
}

.navbar {
    color: #FFFFFF;
    background-color: #616D7E;
}

/* OR*/

.nav {
    color: #FFFFFF;
    background-color: #333;
}
