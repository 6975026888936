.topicList {
  padding: 5px 0 0 10px;
}

.topicListWrapper {
  padding-top: 10px;
}

.topicLabel {
  padding-left: 15px;
  margin: 0;
}

.autoCompleteText input {
  box-sizing: border-box;
  outline: none;
}

.topicInput {
  position: relative;
}

.onBlurClass {
  position: absolute;
  top: 7px;
  right: 5px;
}

.onFocusClass {
  position: absolute;
  top: 13px;
  right: 5px;
}


.autoCompleteText ul {
  list-style-type: none;
  text-align: left;
  margin: 0;
  padding: 0;
  border-top: 1px solid grey;
}

.autoCompleteText li {
  padding: 10px 5px;
  cursor: pointer;
}

.autoCompleteText li:hover {
  text-decoration: underline;
  background-color: rgba(128, 128, 128, .20);
}

.requiredErrorWrapper {
  position: relative;
}

.requiredErrorContainer {
  position: absolute;
  right: -14px;
  top: -6px;
  font-size: 20px;
  color: red;
}

.requiredMandatoryField {
  position: absolute;
  top: -6px;
  font-size: 12px;
  color: red;
}

.createConversationWrapper {
  margin-top: 30px;
}

.selectedTopic{
  margin:5px;
  padding:3px;
  border-radius: 5px;
  border:1px solid #ced4da;
  cursor: pointer;
}

.selectedTopicWrapper{
  border:1px solid #ced4da;
  border-radius: .25rem;
}

.noTopicFound{
   padding-top: 9px;
    padding-left: 15px;
}

.cancelButton{
    width: 15px;
    height: 20px;
    margin-bottom: 3px;
}

.disabledField {
  pointer-events:none;
}
