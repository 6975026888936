* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.4;
}

a {
  color: #333;
  text-decoration: none;
}

.sky-gradient {
  background: linear-gradient(to right, #0acffe 0, #495aff 100%);
}

.error {
  background: #fff0f4; 
  color: #c51244;
}
  
