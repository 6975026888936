svg {
  flex-basis: 100%;
  min-width: 200px;
}
.links line {
  stroke: #aaa;
}
.nodes circle {
  pointer-events: all;
}


